import HTTPService from '@/api/http/HTTPService'
import EmployeeInterface from './EmployeeInterface'
import EmployeeSortInterface from './EmployeeSortInterface'

export default {
  getAll: (siteSlug: string) => HTTPService.get(`site/${siteSlug}/employee`),
  add: (siteSlug: string, payload: EmployeeInterface) => HTTPService.post(`site/${siteSlug}/employee`, payload),
  getOne: (siteSlug: string, employeePage: string) => HTTPService.get(`site/${siteSlug}/employee/${employeePage}`),
  update: (siteSlug: string, employeePage: string, payload: EmployeeInterface) => HTTPService.put(`site/${siteSlug}/employee/${employeePage}`, payload),
  delete: (siteSlug: string, employeePage: string) => HTTPService.delete(`site/${siteSlug}/employee/${employeePage}`),
  sort: (siteSlug: string, payload: EmployeeSortInterface) => HTTPService.post(`site/${siteSlug}/employee/reorder`, payload),
}
