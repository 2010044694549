<template>
  <b-modal
    id="edit-employee-popup"
    :visible="visible"
    size="lg"
    ok-only
    ok-title="Сохранить"
    :title="`Редактирование сотрудника «${employee.name}»`"
    @ok="clickOnSave"
    @hidden="$emit('hidden')"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >
      <div class="mb-1">
        <div>
          <b-form-group
            label="Фото сотрудника"
          >
            <input-file
              v-model="editEmployee.image_key"
              size="lg"
              name="image_key"
            />
            <small
              v-show="errors.has('image_key')"
              class="text-danger"
            >{{ errors.first('image_key') }}</small>
          </b-form-group>
        </div>
        <div>
          <b-form-group
            label="Имя сотрудника"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="editEmployee.name"
              v-validate="'required'"
              name="name"
            />
            <small
              v-show="errors.has('name')"
              class="text-danger"
            >{{ errors.first('name') }}</small>
          </b-form-group>
        </div>
        <div>
          <b-form-group
            label="Должность"
            label-for="job_title"
          >
            <b-form-input
              id="job_title"
              v-model="editEmployee.job_title"
              name="job_title"
            />
          </b-form-group>
        </div>
        <div>
          <b-form-group
            label="Опыт работы"
            label-for="employment_experience"
          >
            <b-form-input
              id="employment_experience"
              v-model="editEmployee.employment_experience"
              name="employment_experience"
            />
          </b-form-group>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import {
  BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import mixinErrorsParse from '@core/mixins/ui/mixinErrorsParse'

import Employee from '@/api/http/models/employee/Employee'

import InputFile from '@core/components/input/InputFile.vue'

export default {
  components: {
    BFormGroup,
    BFormInput,
    InputFile,
  },
  mixins: [mixinErrorsParse],
  props: {
    employeeId: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      default: 'Добавление сотрудника',
    },
  },
  data() {
    return {
      visible: false,
      editEmployee: {},
      employee: {},
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
  },
  async created() {
    const response = await Employee.getOne(this.currentSite.slug, this.employeeId)

    this.employee = response.data || {}

    await this.prepareEmployeeObject()

    this.visible = true
  },
  methods: {
    prepareEmployeeObject() {
      this.editEmployee = JSON.parse(JSON.stringify(this.employee))

      this.editEmployee.image_key = this.editEmployee.image && this.editEmployee.image.key ? this.editEmployee.image.key : ''

      delete this.editEmployee.image
    },

    async clickOnSave(modalEvent) {
      modalEvent.preventDefault()
      this.handleSubmit()
    },

    async handleSubmit() {
      const validate = await this.$validator.validateAll()

      if (validate) {
        const response = await Employee.update(this.currentSite.slug, this.employeeId, this.editEmployee)

        if (response.status === 'success' && response.data) {
          this.$emit('updated', response.data)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Сотрудник обновлен',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$nextTick(() => {
            this.$bvModal.hide('edit-employee-popup')
          })
        }

        if (response.errors) {
          this.mixinErrorsParse(response.errors)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ошибка при обновлении сотрудника',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        }

        this.$nextTick(() => {
          this.$bvModal.hide('page-employee-popup')
        })
      }
    },
  },
}
</script>
