<template>
  <b-card>
    <div class="row mb-2">
      <div class="col">
        <b-button
          class="d-flex align-items-center"
          variant="primary"
          @click.prevent="showAddPopup = true"
        >
          <feather-icon
            icon="PlusIcon"
            size="18"
            class="mr-1"
          />
          Добавить сотрудника
        </b-button>
      </div>
      <div class="col d-flex justify-content-end">
        <b-button
          class="d-inline-flex align-items-center"
          variant="primary"
          :to="{name: 'employees_sort'}"
        >
          <feather-icon
            icon="GridIcon"
            size="18"
            class="mr-1"
          />
          Сортировка
        </b-button>
      </div>
    </div>
    <div
      v-if="employees.length"
    >
      <employee-list
        :employees="employees"
        @updated="updateEmployees"
        @deleted="deleteEmployee"
      />
    </div>
    <div v-else>
      Нет сотрудников
    </div>
    <add-employee-popup
      v-if="showAddPopup"
      @added="addEmployee"
      @hidden="showAddPopup = false"
    />
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BCard, BButton,
} from 'bootstrap-vue'

import Employee from '@/api/http/models/employee/Employee'

import AddEmployeePopup from './AddEmployeePopup.vue'
import EmployeeList from './EmployeeList.vue'

export default {
  components: {
    BCard,
    BButton,
    EmployeeList,
    AddEmployeePopup,
  },
  data() {
    return {
      showAddPopup: false,
      employees: [],
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
  },
  async created() {
    await this.fetchEmployees()
  },
  methods: {
    async fetchEmployees() {
      const response = await Employee.getAll(this.currentSite.slug)

      this.employees = response.data || []
    },
    addEmployee(employee) {
      this.employees.push(employee)
    },
    updateEmployees(employee) {
      const index = this.employees.findIndex(cur => cur.id === employee.id)

      if (index !== -1) {
        this.employees.splice(index, 1, employee)
      }
    },
    deleteEmployee(employee) {
      const index = this.employees.findIndex(cur => cur.id === employee.id)

      if (index !== -1) {
        this.employees.splice(index, 1)
      }
    },
  },
}
</script>
