<template>
  <div>
    <div class="accordion-label__content">
      <div class="row align-items-center w-100">
        <div class="col-lg-3">
          Имя
        </div>
        <div class="col-lg-3">
          Должность
        </div>
        <div class="col-lg-3">
          Опыт работы
        </div>
        <div class="col-lg-3 text-right">
          Действия
        </div>
      </div>
    </div>
    <div
      class="accordion"
      role="tablist"
    >
      <div
        v-for="employee in employees"
        :key="employee.id"
        class="accordion__item"
      >
        <div class="accordion-label accordion-label--disable-arrow">
          <div class="accordion-label__content">
            <div class="row align-items-center w-100">
              <div class="col-12 col-lg-3">
                <div class="d-flex align-items-center">
                  <div
                    v-if="employee.image && employee.image.url"
                    class="content-image-wrap"
                  >
                    <img
                      :src="employee.image.url"
                      alt="cover"
                    >
                  </div>
                  <div class="content-text-wrap">
                    {{ employee.name }}
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-3">
                {{ employee.job_title }}
              </div>
              <div class="col-12 col-lg-3">
                {{ employee.employment_experience }}
              </div>
              <div class="col-12 col-lg-3">
                <div class="d-flex justify-content-end">
                  <b-button
                    variant="outline-primary"
                    class="btn-icon mr-1"
                    @click.prevent="openEditPopup(employee)"
                  >
                    <feather-icon
                      icon="EditIcon"
                      size="12"
                    />
                  </b-button>
                  <b-button
                    variant="outline-danger"
                    class="btn-icon"
                    @click.prevent="openDeletePopup(employee)"
                  >
                    <feather-icon
                      icon="Trash2Icon"
                      size="12"
                    />
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <edit-employee-popup
      v-if="showEditPopup && currentEmployeeId"
      :key="currentEmployeeId"
      :employee-id="currentEmployeeId"
      @updated="$emit('updated', $event)"
      @hidden="hideEditPopup"
    />
    <delete-employee-popup
      v-if="showDeletePopup && currentEmployeeId"
      :key="currentEmployeeId"
      :employee-id="currentEmployeeId"
      @deleted="$emit('deleted', $event)"
      @hidden="hideDeletePopup"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BButton } from 'bootstrap-vue'
import EditEmployeePopup from './EditEmployeePopup.vue'
import DeleteEmployeePopup from './DeleteEmployeePopup.vue'

export default {
  components: {
    BButton,
    EditEmployeePopup,
    DeleteEmployeePopup,
  },
  props: {
    employees: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showEditPopup: false,
      showDeletePopup: false,
      currentEmployeeId: null,
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
  },
  methods: {
    openEditPopup(employee) {
      this.showEditPopup = true
      this.currentEmployeeId = employee.id
    },
    hideEditPopup() {
      this.showEditPopup = false
      this.currentEmployeeId = null
    },
    openDeletePopup(employee) {
      this.showDeletePopup = true
      this.currentEmployeeId = employee.id
    },
    hideDeletePopup() {
      this.showDeletePopup = false
      this.currentEmployeeId = null
    },
  },
}
</script>
